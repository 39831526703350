<template>
  <div v-if="(!isMobile())">
    <div v-if="(keydb)">
    <div v-if="(linkused == false && somethingwrong == false && denied == false)">
    <div style="position: relative;background: white; justify-content: center; align-items: center;">
          <img  style="display:block;margin:0 auto;width:45%;height:45%;"
          src="../assets/staffrequest.svg"             
          alt="Micron Agritech"/>
        </div>
        <br>
        <div>
          <div style="display:block;margin:0 auto;width: 1101px; text-align: center">
            <span style="color: #2F3740; font-size: 36px; font-family: Pulp Display; font-weight: 400; line-height: 44px; word-wrap: break-word">Would you like to add </span>
            <span style="color: #00A394; font-size: 36px; font-family: Pulp Display; font-weight: 400; line-height: 44px; word-wrap: break-word">{{ username }}</span>
            <span style="color: #2F3740; font-size: 36px; font-family: Pulp Display; font-weight: 400; line-height: 44px; word-wrap: break-word"> as a user to your business?</span></div>
          </div>
          <div>
           <span style="display:block;margin:0 auto;text-align: center;">
            <span style="color:red">&#9888;</span> 
            Please procced to deny this request if you do not know the user.</span>
          </div>
          <br>
        <div>
          <br>
                  <div @click="Confirm" >
                      <img style="display:block;margin:0 auto;" src="../assets/approvereq.svg" />
                  </div>
                  <br>
                  <div @click="Deny" >
                      <img style="display:block;margin:0 auto;" src="../assets/deny.svg" />
                  </div>
          </div>
          
          <br>
          <br>
          <div>
            <a href="https://micronagritech.com">
            <img style="display:block;margin:0 auto;width:10%;height: 10%;" src="../assets/micronfooter.svg"/>
            </a>
          </div>
  </div>
  <div v-if="(linkused == true && somethingwrong == false && denied == false)">
    <div style="position: relative;background: white; justify-content: center; align-items: center;">
          <img  style="display:block;margin:0 auto;"
          src="../assets/requestapprvd.svg"             
          alt="Micron Agritech"/>
        </div>
        <br>
        <div>
          <div style="text-align: center"><span style="color: #2F3740; font-size: 36px; font-family: Pulp Display; font-weight: 400; line-height: 44px; word-wrap: break-word"> </span><span style="color: #00A394; font-size: 36px; font-family: Pulp Display; font-weight: 400; line-height: 44px; word-wrap: break-word">{{ username }} </span><span style="color: #2F3740; font-size: 36px; font-family: Pulp Display; font-weight: 400; line-height: 44px; word-wrap: break-word"> has been successfully added to your business as a user.</span></div>
        </div>
        <br>
        <br>
        <br>
        <div>
            <a href="https://micronagritech.com">
            <img style="display:block;margin:0 auto;width:10%;height: 10%;" src="../assets/micronfooter.svg"/>
            </a>
          </div>
    </div>
    <div v-if="(linkused == true && somethingwrong == false && denied == true)">
    <div style="position: relative;background: white; justify-content: center; align-items: center;">
          <img  style="display:block;margin:0 auto;"
          src="../assets/requestdeneied.svg"             
          alt="Micron Agritech"/>
        </div>
        <br>
        <div>
          <div style="text-align: center">
            <span style="color: #2F3740; font-size: 36px; font-family: Pulp Display; font-weight: 400; line-height: 44px; word-wrap: break-word">The request to add </span>
            <span style="color: #00A394; font-size: 36px; font-family: Pulp Display; font-weight: 400; line-height: 44px; word-wrap: break-word">{{username}}</span>
            <span style="color: #2F3740; font-size: 36px; font-family: Pulp Display; font-weight: 400; line-height: 44px; word-wrap: break-word"> as a staff to the business has been denied.</span></div>
        </div>
        <br>
        <br>
        <br>
        <div>
            <a href="https://micronagritech.com">
            <img style="display:block;margin:0 auto;width:10%;height: 10%;" src="../assets/micronfooter.svg"/>
            </a>
          </div>
    </div>
  </div>

  <div v-if="!(keydb)">
    <div>
      <img style="display:block;margin:0 auto;" src="../assets/expired.svg"/>
    </div>
    <br>
    <br>
    <div>
      <a href="https://micronagritech.com">
      <img style="display:block;margin:0 auto;width:10%;height: 10%;" src="../assets/micronfooter.svg"/>
      </a>
    </div>
    
  </div>

  </div>

  <div v-if="(isMobile())">
      <div v-if="(keydb)">
        <div v-if="(linkused == false && somethingwrong == false && denied == false)">
      <div style="position: relative;background: white; justify-content: center; align-items: center;">
            <img  style="display:block;margin:0 auto;width:100%;height:100%;"
            src="../assets/staffrequest.svg"             
            alt="Micron Agritech"/>
          </div>
          <br>
          <div>
            <div style="display:block;margin:0 auto;width: 342px; text-align: center">
              <span style="color: #2F3740; font-size: 20px; font-family: Pulp Display; font-weight: 400; line-height: 24px; word-wrap: break-word">Would you like to add </span>
              <span style="color: #00A394; font-size: 20px; font-family: Pulp Display; font-weight: 400; line-height: 24px; word-wrap: break-word">{{ username }}</span>
              <span style="color: #2F3740; font-size: 20px; font-family: Pulp Display; font-weight: 400; line-height: 24px; word-wrap: break-word"> as a user to your business?</span></div>
            </div>
            <div>
              <br>
            <span style="display:block;margin:0 auto;text-align: center; font-size: 12px;padding-bottom: 10%;">
              <span style="color:red">&#9888;</span> 
              <i> Please procced to deny this request if <br>you do not know the user.</i></span>
            </div>
            <br>
          <div>
            <br>
   
                    <div @click="Confirm" >
                        <img style="display:block;width:75%;height:70%;padding-left: 13%;" src="../assets/approvereq.svg" />
                    </div>
                    <br>
                    <div @click="Deny" >
                        <img style="display:block;width:75%;height:70%;padding-left: 13%;"  src="../assets/deny.svg" />
                    </div>
            </div>
            
            <br>
            <br>
            <div>
        <a href="https://micronagritech.com">
        <img style="display:block;margin:0 auto;padding-top:30%" src="../assets/micronfooter.svg"/>
        </a>
      </div>
        </div>
        <div v-if="(linkused == true && somethingwrong == false && denied == false)">
          <div style="position: relative;background: white; justify-content: center; align-items: center;">
                <img  style="display:block;margin:0 auto;width: 90%;height:90%;"
                src="../assets/requestapprvd.svg"             
                alt="Micron Agritech"/>
              </div>
              <br>
              <div>
                <div style="display:block;margin:0 auto;width: 342px; text-align: center;">
                  <span style="color: #2F3740; font-size: 20px; font-family: Pulp Display; font-weight: 400; line-height: 24px; word-wrap: break-word"> </span>
                  <span style="color: #00A394; font-size: 20px; font-family: Pulp Display; font-weight: 400; line-height: 24px; word-wrap: break-word">{{ username }} </span>
                  <span style="color: #2F3740; font-size: 20px; font-family: Pulp Display; font-weight: 400; line-height: 24px; word-wrap: break-word"> has been successfully added to your business as a staff.</span></div>
              </div>
            <br>
            <br>
              <div>
            <a href="https://micronagritech.com">
            <img style="display:block;margin:0 auto;padding-top:30%" src="../assets/micronfooter.svg"/>
            </a>
          </div>
          </div>
        <div v-if="(linkused == true && somethingwrong == false && denied == true)">
        <div style="position: relative;background: white; justify-content: center; align-items: center;">
              <img  style="display:block;margin:0 auto;width: 90%;height:90%;"
              src="../assets/requestdeneied.svg"             
              alt="Micron Agritech"/>
            </div>
            <br>
            <div>
              <div style="text-align: center">
                <span style="color: #2F3740; font-size: 20px; font-family: Pulp Display; font-weight: 400; line-height: 24px; word-wrap: break-word">The request to add </span>
                <span style="color: #00A394; font-size: 20px; font-family: Pulp Display; font-weight: 400; line-height: 24px; word-wrap: break-word">{{username}}</span>
                <span style="color: #2F3740; font-size: 20px; font-family: Pulp Display; font-weight: 400; line-height: 24px; word-wrap: break-word"> as a staff to the business has been denied.</span></div>
            </div>
            <br>
            <br>
            <br>
            <div>
          <a href="https://micronagritech.com">
          <img style="display:block;margin:0 auto;padding-top:30%" src="../assets/micronfooter.svg"/>
          </a>
        </div>
        </div>
    </div>

    <div v-if="!(keydb)">
      <div>
        <img style="display:block;margin:0 auto;width:90%;height:90%;" src="../assets/expired.svg"/>
      </div>
      <br>
      <br>
      <div>
        <a href="https://micronagritech.com">
        <img style="display:block;margin:0 auto;padding-top:65%" src="../assets/micronfooter.svg"/>
        </a>
      </div>
      
    </div>
  </div>


</template>

<script>
import { useStore } from "vuex";
import { Crisp } from "crisp-sdk-web";
Crisp.configure('cf47ddbf-83c5-4b13-bf4c-392d7bd5f775',{
    autoload: false
});

export default {
  name: "Login",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
    id:"",
    formnumber:"",
    username:"",
    linkused:false,
    somethingwrong:false,
    keydb:false,
    keyid:"",
    denied:false,
    };
  },
  methods: {
    isMobile() {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
 },
    Confirm() {
      this.disableBtn = true;
      let part = this.formnumber.split('/')
      this.id = part[4]
      this.role = part[5]
      this.store
        .dispatch("User/RecoveryUserAssign",{
          key:this.id,
          role:this.role
        })
        .then((result)=>{
          this.linkused = true;
          console.log("Registered user to the business",result);
        })
        .catch((err)=>{
          this.somethingwrong = true;
          console.log("Error while adding user to business",err);
        })

    },
    Deny() {
      this.disableBtn = true;
      let part = this.formnumber.split('/')
      this.id = part[4]
      this.store
        .dispatch("User/RecoveryUserAssignDeny",{
          key:this.id
        })
        .then((result)=>{
          console.log(" Request deleted",result);
          this.linkused = true;
          this.denied = true;
        })
        .catch((err)=>{
          this.somethingwrong = true;
          console.log("Error while deleting the request",err);
        })

    },
    keycheck(){
      
      return new Promise((resolve,reject)=>{
        console.log("value of key",this.keyid);
    this.store
      .dispatch("User/KeyCheckDb",{
        key:this.keyid
      })
      .then((result)=>{
        if(result.data.messages != 'Key not found'){
          this.keydb = true;
          resolve(this.keydb)
        }
        else{
          this.keydb = false;
          resolve(this.keydb)
        }

      }).catch((err)=>{
        console.log("Something going wrong ",err);
        reject(err);
      })

      })
      .catch((error)=>{
        console.log("Error from hereee ",error);
      })

    }
  },
  mounted(){
    this.formnumber = (this.$route.path);
    let parts = this.formnumber.split('/')
    this.username = parts[3].replace('_',' ');
    console.log("Here is the value of key",this.username);
    let parttemp = this.formnumber.split('/');
    this.keyid = parttemp[4];
    //call a function to check if ley exists in the system / or is deleted
    this.keycheck();

    setTimeout(() => {
            console.log("chatoption ", this.chatoption);
            if((this.keydb == false)){
            Crisp.load();
        }
    }, 2000);
    
  }
};
</script>

<style>
.placeholder-login {
  font-family: Arial, sans-serif;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  background: #ffffff;
}

.pane:first-child {
  position: relative;
  flex: 0 0 44%;
  width: 44%;
  background: #35bba4;
  color: #fff;
}

.pane:nth-child(2) {
  flex: 0 0 56%;
  width: 56%;
  align-self: center;
}

.header {
  height: auto;
  width: 100%;
  padding: 20px;
  font-weight: 700;
  font-size: 6em;
  text-align: center;
}

.app_shoutout {
  height: auto;
  width: 100%;
  padding: 10px;
  font-weight: 500;
  font-size: 30px;
}

.image-holder img {
  height: auto;
  width: 70%;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

.loginContainer {
  width: 50%;
  height: auto;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
}

.logoblk img {
  padding-bottom: 20px;
  max-width: 200px;
  text-align: center;
  margin: 0 auto;
}

.loginForm {
  text-transform: uppercase;
  width: 100%;
  height: auto;
  display: block;
}

.loginForm label {
  display: block;
  font-size: 0.8em;
  color: #4d4d4d;
  padding: 10px;
}

.loginForm .input {
  height: 50px;
  width: 100%;
  font-size: 27px;
  color: #4d4d4d !important;
  text-align: center;
  display: block;
  background: #fff !important;
  outline: 0;
  border: 1px solid #c8ced5;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 1px;
  box-sizing: border-box;
}

.loginForm .input:hover,
.loginForm .input:focus {
  border-color: #35bba4;
}

.btn-login {
  height: 40px;
  outline: 0;
  border: none;
  background: inherit;
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  font-size: 17px;
  display: block;
  box-sizing: border-box;
  text-align: center;
  border-radius: 5px;
}

.btn-teal {
  background: #35bba4;
  color: #fff;
}

.btn-teal:hover {
  background: #31b39d;
  color: #fff;
}

.error {
  height: auto;
  width: 100%;
  background: #e34343;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #c52f2f;
}

/* If screen 900px or less. */
@media only screen and (max-width: 910px) {
  .pane:first-child {
    display: none;
  }

  .pane:nth-child(2) {
    flex: 0 0 100%;
    width: 100%;
    align-self: center;
  }

  .loginContainer {
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
  }
}
</style>